<template>
<div class="row align-items-end">
  <div v-if="progress" class="col-12">
    <b-progress :value="100" variant="info" animated class="mt-1"></b-progress>
  </div>
  <div class="col">
    <label for="inline-form-input-name">Тип списання</label>
    <select class="form-control form-control-sm" v-model="form.typeOfWriteOff">
      <option value=""> -- </option>
      <option value="static">Статичний</option>
      <option value="dynamic">Динімічний</option>
    </select>
  </div>
  <div class="col">
    <label for="inline-form-input-name">Група</label>
    <select class="form-control form-control-sm" v-model="form.group" @change="changeGroup">
      <option value=""> -- </option>
      <optgroup v-for="group in groups" :key="group.id" :label="group.name">
        <option v-for="child in group.children" :key="child.id" :value="child.id">{{ child.name }}</option>
      </optgroup>
    </select>
  </div>
  <div class="col">
    <label for="inline-form-input-name">Код товару</label>
    <input type="text" class="form-control form-control-sm" v-model="form.sku" @input="searchBySku($event)">
  </div>
  <div class="col">
    <label for="inline-form-input-name">Назва товару</label>
    <list-select
      v-if="filterNames"
      :list="products"
      option-value="id"
      option-text="name"
      :selected-item="productSelected"
      v-model="form.product"
      @select="onSelectProduct"
      class="form-control form-control-sm"></list-select>
  </div>
  <div class="col">
    <label for="inline-form-input-name">Характеристика</label>
    <list-select
      v-if="filterNames"
      :list="characteristics"
      option-value="id"
      option-text="name"
      :selected-item="characteristicSelected"
      v-model="form.characteristic"
      @select="onSelectCharacteristic"
      class="form-control form-control-sm"></list-select>
  </div>
  <div class="col">
    <label for="inline-form-input-name">Кількість</label>
    <input type="text" class="form-control form-control-sm" v-model.number="form.quantity" :disabled="form.typeOfWriteOff === 'dynamic'">
  </div>
  <div class="col">
    <label for="inline-form-input-name">Одиниця виміру</label>
    <!--<input type="text" class="form-control form-control-sm" v-model="form.unit">-->
    <select class="form-control form-control-sm" v-model="form.unit">
      <option value="шт">шт</option>
      <option value="л">л</option>
      <option value="мл">мл</option>
    </select>
  </div>
  <div class="col align-middle">
    <b-button pill size="sm" variant="outline-primary" @click="groupAdd"><fa icon="plus" /></b-button>
  </div>
</div>
</template>
<script>
import { ListSelect } from 'vue-search-select'
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
  props: {
    filterNames: null,
    groups: null,
    items: {
      required: true
    }
  },
  components: {
    ListSelect
  },
  data: () => ({
    characteristicSelected: {
      id: '',
      name: ''
    },
    productSelected: {
      id: '',
      name: ''
    },
    form: {
      typeOfWriteOff: 'static',
      group: '',
      sku: '',
      product: '',
      characteristic: '',
      quantity: 0,
      unit: 'шт'
    },
    initTrigger: false,
    progress: false
  }),
  computed: {
    products () {
      this.initTrigger
      if (this.filterNames) {
        if (this.form.group !== '' || this.form.characteristic !== '' || this.form.sku  !== '') {
          let filters = {}
          if (this.form.group !== '') {
            filters.group_id = this.form.group
          }
          if (this.form.characteristic !== '') {
            filters.characteristic = this.form.characteristic
          } 
          if (this.form.sku !== '') {
            filters.sku = this.form.sku
          }
          const out = this.filterNames.products.filter(item => {
            if (item.id !== '') {
              return Object.keys(filters).every(filter => {
                if (filter === 'characteristic' && item.characteristicsIds.length > 0) {
                  return item.characteristicsIds.includes(filters[filter])
                } else {
                  return filters[filter] === item[filter]
                }
              })
            }
          })
          return out
        } else {
          return this.filterNames.products
        }
      }
    },
    characteristics () {
      this.initTrigger
      if (this.filterNames) {
        if (this.form.product !== '') {
          let filters = {}
          if (this.form.group !== '') {
            filters.group_id = this.form.group
          }
          if (this.form.product !== '') {
            filters.product_id = this.form.product
          } 
          if (this.form.sku !== '') {
            filters.sku = this.form.sku
          }
          const out = this.filterNames.characteristics.filter(item => {
            return Object.keys(filters).every(filter => {
              return filters[filter] === item[filter]
            })
          })
          return out
        } else {
          return [{ id: '', name: ''}]
        }
      }
    }
  },
  methods: {
    groupAdd () {
      const products = []
      this.items.forEach(item => {
        const chars = []
        if (item.characteristics.length > 0) {
          item.characteristics.forEach(c => {
            const stages = []
            if (c.stages.length > 0) {
              c.stages.forEach(s => {
                if (s.selected) {
                  stages.push(s.id)
                }
              })
            }
            chars.push({ id: c.id, selected: c.selected, stages: stages })
          })
        }
        products.push({ id: item.id, selected: item.selected, chars: chars })
      })
      this.bulkAdd(products)
    },
    async bulkAdd (selected) {
      if (this.characteristics.length > 0 && this.characteristicSelected.id === '') {
        Swal.fire({
          title: 'Помилка!',
          text: 'Характеристика обовязкова для цього товару',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      } else {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'specification/bulk', { input: this.form, output: selected }).then(resp => {
          if (resp.data.status) {
            this.$emit('fetch')
            this.clearForm()
          }
        })
      }
    },
    searchBySku (e) {
      const sku = e.target.value
      if (sku.length <= 0) {
        this.productSelected = { id: '', name: '' }
        this.form.product = ''
        this.initTrigger = !this.initTrigger
      } else {
        const product = this.filterNames.products.find(p => p.sku === sku)
        if (typeof product !== 'undefined') {
          this.productSelected = product
          this.form.group = product.group_id
          this.form.product = product.id
        }
      }
    },
    onSelectCharacteristic (val) {
      this.characteristicSelected = val
      this.form.characteristic = val.id
    },
    onSelectProduct (val) {
      this.productSelected = val
      this.form.product = val.id
      this.initTrigger = !this.initTrigger
      const product = this.filterNames.products.find(p => p.id === val.id)
      const char = this.filterNames.characteristics.find(c => c.product_id === val.id)
      if (typeof char !== 'undefined') {
        this.form.group = char.group_id
        this.form.sku = char.sku
      } else {
        if (typeof product !== 'undefined') {
          this.form.group = product.group_id
          this.form.sku = product.sku
        }
      }
    },
    changeGroup () {
      this.clearForm()
    },
    clearForm () {
      this.form.product = ''
      this.form.sku = ''
      this.form.characteristic = ''
      this.form.quantity = 0
      this.characteristicSelected = { id: '', name: '' }
      this.productSelected = { id: '', name: '' }
    }
  }
}
</script>
